
.footer {
   height: 70px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   font-size: 1.2rem;
   flex: 0 0 auto;
   
}

@media (max-width: 660px) {

   .footer {
      font-size: 3vw;
   }
}