.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(98vh - 70px);
}

.app {
  text-align: center;
  flex: 1 0 auto;
}

.background_image {
  background-image: url('./image/photo1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: blur(5px);
  opacity: 0.1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

h1 {
  font-size: 90px;
  margin-top: 20px;
}

button {
  padding: 15px;
  font-size: 30px;
  border-radius: 15px;
  margin: 10px;
  border: 0;
  cursor: pointer;
}

@media (max-width: 600px) {
  button {
    font-size: 20px;
    margin: 5px;
    padding: 5px;
  }
}