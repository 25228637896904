.wrapper {
   width: 90%;
   margin: 2% auto;
   background-color: #1a4979;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 20px;
}

.wrapper h2 {
   font-size: 3vw;
   color: white;
}

.wrapper_card {
   display: flex;
   align-items: center;
}

.wrapper_card__img {
   width: 20%;
   margin: 20px 20px;
   border-radius: 10px;
   animation: card__img;
   animation-duration: 4s; 
   animation-iteration-count: infinite;
}

@keyframes card__img {   
   0% {     
      box-shadow: 0px 0px 25px 5px rgb(16, 21, 90);  
   }
   50% {
      box-shadow: none;
   }   
   100% {
      box-shadow: 0px 0px 25px 5px rgb(16, 21, 90);
   }
}

.wrapper_card_info__item {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin: 10px auto;
}

.wrapper_card_info__item span {
   margin-left: 10px;
   font-size: 1.2rem;
   color: white;
}

.wrapper_card_info__item a {
   color: white;
   text-decoration: none;
}

.wrapper_card_info__img {
   width: 7%;
   border-radius: 10%;
}

.wrapper_card_info__item:hover > .wrapper_card_info__img{
   animation: card_info__img;
   animation-duration: 0.8s;
   animation-iteration-count: inherit;
}

@keyframes card_info__img {   
   0% {     
      transform: rotateY(0deg);  
   }
   50% {
      transform: rotateY(360deg);
   }   
   100% {
      transform: rotateY(0deg);  
   }
 }

@media (max-width: 860px) {
   .wrapper_card {
      flex-direction: column;
      align-items: center;
   }
   .wrapper_card__img {
      width: 30%;
      margin: 0px 5px;
   }
   .wrapper_card_info__item {
      margin: 5px auto;
   }
   .wrapper_card_info__item span {
      font-size: 3vw;
   }
}