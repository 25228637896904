.wrapper {
   width: 90%;
   margin: 2% auto;
   background-color: #1a4979;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 20px;
}