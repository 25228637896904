* {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-family: 'Noto Sans Display', sans-serif;
    color: white;
    background-color: #112d49;
    
}


